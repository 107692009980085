<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>设置</el-breadcrumb-item>
      <el-breadcrumb-item>新增/编辑荣誉与正式</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="boxLayout">
      <el-form
        :model="basicInfoForm"
        label-width="95px"
        ref="form"
        :rules="rules"
      >
        <el-card class="box-card" shadow="never">
          <el-form-item label="资料标题" prop="name">
            <el-input v-model="basicInfoForm.name"></el-input>
          </el-form-item>
          <el-form-item label="资料封面">
            <el-upload
              ref="upload"
              class="upload-demo"
              action="#"
              :accept="'jpg,jpeg,png'"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
              :http-request="httpRequest"
            >
            <img
                v-if="basicInfoForm.image_src  != ''"
                :src="basicInfoForm.image_src"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
            </el-upload>
            <el-button  type="text"  @click="chooseBanner('banner')">从素材库选择</el-button>
            <p class="font-12 color-999">
                *
                请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片/若不上传则默认分享当前页面截图
              </p>
          </el-form-item>
          <el-form-item  label="资料类型">
            <el-radio-group v-model="basicInfoForm.type" @change="switchRadio">
              <el-radio label="2">图片</el-radio>
              <el-radio label="1">视频</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="资料图片"  v-if="basicInfoForm.type == 2">
            <el-upload
              ref="upload"
              class="upload-demo"
              action="#"
              :accept="'jpg,jpeg,png'"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              list-type="picture-card"
              :http-request="httpRequest2"
            >
            <img
                v-if="basicInfoForm.video_src != ''"
                :src="basicInfoForm.video_src"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon">本地上传</i>
            </el-upload>
            <el-button  type="text"  @click="chooseBanner('poster')">从素材库选择</el-button>
            <p class="font-12 color-999">
              *
              请按xxx*xxx尺寸上传png/jpg格式且小于2m的图片/若不上传则默认分享当前页面截图
            </p>
          </el-form-item>
          <el-form-item  label="资料视频" v-else>
            <el-button class="avatar-uploader video" @click="chooseVideo">
              <video v-if="basicInfoForm.video_src !== ''" width="178px" controls>
                <source :src="basicInfoForm.video_src" />
              </video>
              <i
                class="el-icon-delete"
                v-if="basicInfoForm.video_src !== ''"
                @click.stop="basicInfoForm.video_src = ''"
              ></i>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-button>
            <p class="font-12 color-999">视频最长支持10分钟</p>
          </el-form-item>
          <el-form-item label="上架设置">
            <el-radio-group v-model="basicInfoForm.status">
              <el-radio label="1">上线</el-radio>
              <el-radio label="0">下线</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-card>
      </el-form>
    </div>
    <div class="operateBtn">
      <el-button size="mini" type="primary" @click="submit('form')"
        >保存</el-button
      >
      <el-button size="mini" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible2"
      width="80%"
      :close-on-click-modal="false"
    >
      <MaterialLibrary :type="imgType" @selectImg="selectImg" @insertVideoHandler="insertVideoHandler"/>
      <span slot="footer" class="dialog-footer text-center">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="选择图片"
      :visible.sync="dialogVisible"
      width="80%"
      :close-on-click-modal="false"
    >
      <imageDialog :isSingle="true" @closeImageDialog="closeImageDialog" @confirmImageDialog="confirmImageDialog" />
      
    </el-dialog>
  </div>
</template>
<script>
import MaterialLibrary from "../../../components/materialLibraryDialog/index";
import imageDialog from "../../../components/imageDialog.vue";
export default {
  name: "addMessageConfig",
  components: { MaterialLibrary, imageDialog },
  data() {
    return {
      title: '',
      dialogVisible: false,
      dialogVisible2:false,
      imgType: "",
      type: "",
      basicInfoForm: {
        name: '',
        image_src: '',
        video_src: '',
        type: '2',
        status: '1'
      },
      isImg: '',
      rules: {
        name: { required: true, message: "请输入", trigger: "blur" },
      },
      formData: null
    };
  },
  mounted() {
    this.axios({
      method: "get", //请求方法
      headers: {
        "Content-Type": "application/json",
        "X-Access-Token": this.storage.get("__token"),
        Authorization: `Bearer ${this.storage.get("__token")}`,
      },
      url: `https://time.api.boyalife.cn/v1/cms/AboutStuff/${this.$route.query.uuid}`,
    })
      .then((res) => {
        let d = res.data.result;
        for (let i in d) {
          this.basicInfoForm[i] = d[i];
        }
    
      })
      .catch((err) => {
        this.$message.error(err.response.data.error.message);
      });
  },
  methods: {
    confirmImageDialog(item) {
      if (this.isImg == 'banner') {
        this.basicInfoForm.image_src = item[0].url;
      } else {
        this.basicInfoForm.video_src = item[0].url;
      }
      this.dialogVisible = false;
    },
    switchRadio (v) {
      this.basicInfoForm.video_src = ''
    },
    closeImageDialog () {
      this.dialogVisible = false
    },
     // 上传图片
     httpRequest() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.image_src =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    httpRequest2() {
      this.$api.file
        .uploadFile(this.formData)
        .then((res) => {
          this.basicInfoForm.video_src =  res[0].url;
          this.loading = false;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    beforeAvatarUpload(file) {
      this.loading = true;
      this.formData = new FormData();
      this.formData.append("file[]", file);
    },
    // 选择banner
    chooseBanner(str) {
      this.title = "选择图片";
      if (str) {
        this.isImg = str
      }
      this.imgType = "chooseThumbnail_image";
      this.dialogVisible = true;
    },
    // 选择封面
    selectImg(item) {
      if (this.isImg != '') {
        this.basicInfoForm.video_src = item.url
      } else {
        this.basicInfoForm.video_src = '';
      }
      this.dialogVisible = false;
      this.isImg = ''
    },
    chooseVideo() {
      this.imgType = "chooseVideo";
      this.dialogVisible2 = true;
      this.title = "选择视频";
    },
    insertVideoHandler(url) {
      this.dialogVisible2 = false;
      this.basicInfoForm.video_src = url;
    },
    submit(form) {
      this.$refs[form].validate((valid) => {
        if (this.basicInfoForm.image_src == '') {
          this.$message.error('请上传封面图')
          return false
        }
        if (this.basicInfoForm.video_src == '') {
          this.$message.error('请上传资料内容')
          return false
        }
        if (valid) {
          if (Object.keys(this.$route.query).length > 0) {
            this.axios({
              method: "put", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: `https://boya-service-api.gymooit.cn/v1/cms/AboutStuff/${this.$route.query.uuid}`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1)
              })
              .catch((err) => {
                this.$message.error(err.response.data.error.message);
              });
          } else {
            this.axios({
              method: "post", //请求方法
              headers: {
                "Content-Type": "application/json",
                "X-Access-Token": this.storage.get("__token"),
                Authorization: `Bearer ${this.storage.get("__token")}`,
              },
              data: this.basicInfoForm,
              url: `https://boya-service-api.gymooit.cn/v1/cms/AboutStuff`,
            })
              .then((res) => {
                this.$message.success("保存成功！");
                this.$router.go(-1)

              })
              .catch((err) => {
                // this.$message.error(err.response.data.error.message);
              });
          }
         
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
>>> .el-form-item {
  width: 600px;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar-uploader {
  position: relative;
}
.avatar-uploader .el-icon-delete {
  font-size: 20px;
  position: absolute;
  right: -40px;
  bottom: 0px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
>>>.el-upload--picture-card{
  width: 178px;
  height: 178px;
  position: relative;
}
</style>
